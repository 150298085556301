<template>
  <div>
    <b-card no-body class="mb-5">
      <b-card-header header-tab="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="supplier_id">{{ $t('purchases_payments_periodic_reports.supplier') }}</label>
                <multiselect id="supplier_id" v-model="supplier"
                             :placeholder="$t('purchases_payments_periodic_reports.supplier')"
                             label="full_name"
                             track-by="id"
                             :options="suppliers"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getSuppliers($event)">
                </multiselect>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('purchases_payments_periodic_reports.added_by') }}</label>
                <multiselect id="user_id"
                             v-model="user"
                             :placeholder="$t('purchases_payments_periodic_reports.added_by')"
                             label="name"
                             track-by="id"
                             :options="users"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getUsers($event)">
                </multiselect>
              </div>


              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="payment_method">{{ $t('purchases_payments_periodic_reports.payment_method') }}</label>
                <select name="" id="payment_method" v-model="filters.payment_method" type="text" class="custom-select">
                  <option value="">{{ $t('all') }}</option>
                  <option v-for="(row, index) in payment_method_list" :value="row.id" :key="'payment_method'+index">{{ row.title }}</option>
                </select>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('purchases_payments_periodic_reports.from_date') }}</label>
                <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('purchases_payments_periodic_reports.to_date') }}</label>
                <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
              </div>

              <div class="col-md-6 mt-2 mb-2 pt-10">
                <div>
                  <b-button-group>
                    <b-button @click="changeIntervalType('current_day')" :variant="filters.period=='current_day'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_date') }}</b-button>
                    <b-button @click="changeIntervalType('current_month')" :variant="filters.period=='current_month'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_month') }}</b-button>
                    <b-button @click="changeIntervalType('current_year')" :variant="filters.period=='current_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_year') }}</b-button>
                    <b-button @click="changeIntervalType('financial_year')" :variant="filters.period=='financial_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.financial_year') }}</b-button>
                    <!--                    <b-button  @click="changeFinancialYear" :variant="filters.financial_year==1? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>-->
                  </b-button-group>

                </div>
                <div class="mt-5">
                  <b-button-group>
                    <b-button @click="changeIntervalType('yesterday')" :variant="filters.period=='yesterday'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.yesterday') }}</b-button>
                    <b-button @click="changeIntervalType('last_month')" :variant="filters.period=='last_month'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.last_month') }}</b-button>
                    <b-button @click="changeIntervalType('last_year')" :variant="filters.period=='last_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.last_year') }}</b-button>
                  </b-button-group>

                </div>
              </div>

              <div class="form-group d-inline-flex col-md-6 mt-10">
                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                  {{ $t('display_report') }}
                </button>
                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <div class="card card-custom">
      <div class="card-body">
        <b-tabs content-class="mt-3">
          <b-tab :title="$t('summery')" :active="tab == 'summery' || tab == null">
            <div class="row mb-10">
              <div class="col-md-12 mt-10" id="summaryTableTitle">
                <h4 class="text-center">{{ $t('purchases_payments_periodic_reports.purchases_payments_periodic_reports') }}</h4>
                <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from') }} {{ filters.from_date }} {{ $t('to') }} {{ filters.to_date }}</h5>
              </div>
<!--              <div class="col-md-6 mt-10" v-if="line_invoice_details">-->
<!--                <LineChart :data-line-invoice="line_invoice_details" :currency-name="currency_name"/>-->
<!--              </div>-->

            </div>
            <div class="row mb-10">
              <div class="col-12" v-if="data_report_details">
                <div class="table-responsive">
                  <div class="row justify-content-end p-4">
                    <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
<!--                      <download-excel-->
<!--                          class="dropdown-item"-->
<!--                          :fetch="salesInvoiceExport"-->
<!--                          :fields="json_fields"-->
<!--                          :name="$t('purchases_payments_periodic_reports.purchases_payments_periodic_reports')+'.xls'">-->
<!--                        <i class="la la-file-excel"></i>{{ $t('excel') }}-->
<!--                      </download-excel>-->
                      <button class="dropdown-item" @click="convertTableToExcelReport('summaryTable')">
                        <span><i class="fa fa-file-excel"></i> <span>{{ $t('excel') }}</span></span>
                      </button>
                      <button class="dropdown-item" @click="printData('summaryTable', 'print')">
                        <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                      </button>
<!--                      <button class="dropdown-item" @click="printData('summaryTable', 'pdf')">-->
<!--                        <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>-->
<!--                      </button>-->
                      <button class="dropdown-item" @click="getAllDataForExportSummary('sectionForExport', 'excel')">
                        <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                      </button>
                    </b-dropdown>
                  </div>
                  <table class="table table-bordered" id="summaryTable">
                    <thead>
                    <tr>
                      <th>{{ $t('purchases_payments_periodic_reports.' + periodLabel) }}</th>
                      <th>{{ $t('purchases_payments_periodic_reports.total') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row2, index2) in data_report_summary" :key="'a'+getRandom()+index2">
                      <td>{{ row2.label }}</td>
                      <td>{{ row2.total }}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                      <td><b>{{ $t('purchases_payments_periodic_reports.total') }}</b></td>
                      <th>{{ summary_total_sum ? summary_total_sum : 0 }}</th>
                    </tr>
                    </tfoot>
                  </table>
                </div>

              </div>

            </div>
            <div class="row">
              <div class="col-12 mt-5 text-center">
                <button class="btn btn-warning" @click="loadMore">
                  <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                  {{ $t('load_more') }}
                </button>
              </div>
            </div>
          </b-tab>

          <b-tab :title="$t('details')" :active="tab == 'details'">
            <div class="mt-3">
              <div class="row mb-10">
                <div class="col-md-12 mt-10" id="summaryTableDetailsTitle">
                  <h4 class="text-center">{{ $t('purchases_payments_periodic_reports.purchases_payments_periodic_reports') }}</h4>
                  <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from') }} {{ filters.from_date }} {{ $t('to') }} {{ filters.to_date }}</h5>
                </div>
<!--                <div class="col-md-6 mt-10" v-if="line_invoice_details">-->
<!--                  <LineChart :data-line-invoice="line_invoice_details" :currency-name="currency_name"/>-->
<!--                </div>-->
              </div>
              <div class="row mb-10">
                <div class="col-12" v-if="data_report_details">
                  <div class="table-responsive">
                    <div class="row justify-content-end p-4">
                      <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
<!--                        <download-excel-->
<!--                            class="dropdown-item"-->
<!--                            :fetch="salesInvoiceDetailsExport"-->
<!--                            :fields="json_fields_details"-->
<!--                            :name="$t('purchases_payments_periodic_reports.purchases_payments_periodic_reports')+'.xls'">-->
<!--                          <i class="la la-file-excel"></i>{{ $t('excel') }}-->
<!--                        </download-excel>-->
                        <button class="dropdown-item" @click="convertTableToExcelReport('summaryTableDetails')">
                          <span><i class="fa fa-file-excel"></i> <span>{{ $t('excel') }}</span></span>
                        </button>
                        <button class="dropdown-item" @click="printData('summaryTableDetails', 'print')">
                          <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                        </button>
<!--                        <button class="dropdown-item" @click="printData('summaryTableDetails', 'pdf')">-->
<!--                          <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>-->
<!--                        </button>-->
                        <button class="dropdown-item" @click="getAllDataForExport('sectionForExportDetails', 'excel')">
                          <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                        </button>
                      </b-dropdown>
                    </div>
                    <table class="table table-bordered" id="summaryTableDetails">
                      <thead>
                      <tr>

                        <th>#</th>
                        <th>{{ $t('purchases_payments_periodic_reports.invoice_number') }}  </th>
                        <th>{{ $t('purchases_payments_periodic_reports.payment_code') }}  </th>
                        <th v-if="filters.filter_by != 'supplier'">{{ $t('purchases_payments_periodic_reports.supplier') }}</th>
                        <th v-if="!isPeriod">{{ $t('purchases_payments_periodic_reports.date') }}</th>
                        <th v-if="filters.filter_by != 'user'">{{ $t('purchases_payments_periodic_reports.employee') }}</th>
                        <th>{{ $t('purchases_payments_periodic_reports.payment_method') }}</th>
                        <th>{{ $t('purchases_payments_periodic_reports.total') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <template v-for="(row, index) in data_report_details">
                        <tr :key="'a'+index">
                          <td :colspan="filters.filter_by == 'payment_method' ? 8 : 7">{{ row.label }}</td>
                        </tr>
                        <tr v-for="(row2, index2) in row.invoices" :key="'a'+getRandom()+index2">
                          <td>{{ index2 + 1 }}</td>
                          <td>{{ row2.code }}</td>
                          <td>{{ row2.payment_code }}</td>
                          <td v-if="filters.filter_by != 'supplier'">{{ row2.supplier_name }}</td>
                          <td v-if="!isPeriod">{{ row2.created_at }}</td>
                          <td v-if="filters.filter_by != 'user'">{{ row2.user_name }}</td>
                          <td>{{ row2.payment_method_name }}</td>
                          <td>{{ row2.amount }}</td>
                        </tr>
                        <tr :key="'b'+index">
                          <td :colspan="filters.filter_by == 'payment_method' ? 7 : 6">{{ $t('purchases_payments_periodic_reports.total') }}</td>
                          <td>{{ row.total }}</td>
                        </tr>
                      </template>

                      </tbody>
                      <tfoot>
                      <tr>
                        <td :colspan="filters.filter_by == 'payment_method' ? 7 : 6"><b>{{ $t('purchases_payments_periodic_reports.total') }}</b></td>
                        <th>{{ details_total_sum ? details_total_sum : 0 }}</th>
                      </tr>
                      </tfoot>
                    </table>
                  </div>

                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-5 text-center">
                  <button class="btn btn-warning" @click="loadMoreDetails">
                    <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                    {{ $t('load_more') }}
                  </button>
                </div>
              </div>
            </div>
          </b-tab>
          <template #tabs-end>
            <li class="nav-item  d-flex my-auto">
              <select name="" id="" v-model="filters.filter_by" class="custom-select border-0 pt-0 pb-0 h-auto shadow-none">
                <option :value="null" :disabled="true">{{ $t('select_filter') }}</option>
                <option value="daily">{{ $t('daily') }}</option>
                <option value="weekly">{{ $t('weekly') }}</option>
                <option value="monthly">{{ $t('monthly') }}</option>
                <option value="yearly">{{ $t('yearly') }}</option>
                <option value="user">{{ $t('purchases_payments_periodic_reports.added_by') }}</option>
                <option value="supplier">{{ $t('supplier') }}</option>
                <option value="payment_method">{{ $t('payment_method') }}</option>
              </select>

            </li>
          </template>
          <!-- <b-tab> -->
          <!-- </b-tab> -->
        </b-tabs>

      </div>
    </div>
    <div class="export-container">
      <div class="section-for-export">
        <table class="table table-bordered" id="sectionForExport">
          <thead>
          <tr>
            <th>{{ $t('purchases_payments_periodic_reports.' + periodLabel) }}</th>
            <th>{{ $t('purchases_payments_periodic_reports.total') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row2, index2) in dataSummaryList" :key="'a'+getRandom()+index2">
            <td>{{ row2.label }}</td>
            <td>{{ row2.total }}</td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <td><b>{{ $t('purchases_payments_periodic_reports.total') }}</b></td>
            <th>{{ dataTotalSummaryList ? dataTotalSummaryList : 0 }}</th>
          </tr>
          </tfoot>
        </table>
        <table class="table table-bordered" id="sectionForExportDetails">
          <thead>
          <tr>

            <th>#</th>
            <th>{{ $t('purchases_payments_periodic_reports.invoice_number') }}  </th>
            <th>{{ $t('purchases_payments_periodic_reports.payment_code') }}  </th>
            <th v-if="filters.filter_by != 'supplier'">{{ $t('purchases_payments_periodic_reports.supplier') }}</th>
            <th v-if="!isPeriod">{{ $t('purchases_payments_periodic_reports.date') }}</th>
            <th v-if="filters.filter_by != 'user'">{{ $t('purchases_payments_periodic_reports.employee') }}</th>
            <th>{{ $t('purchases_payments_periodic_reports.payment_method') }}</th>
            <th>{{ $t('purchases_payments_periodic_reports.total') }}</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(row, index) in dataDetailsList">
            <tr :key="'aeewfew'+index">
              <td :colspan="filters.filter_by == 'payment_method' ? 8 : 7">{{ row.label }}</td>
            </tr>
            <tr v-for="(row2, index2) in row.invoices" :key="'abtgg'+index+getRandom()+index2">
              <td>{{ index2 + 1 }}</td>
              <td>{{ row2.code }}</td>
              <td>{{ row2.payment_code }}</td>
              <td v-if="filters.filter_by != 'supplier'">{{ row2.supplier_name }}</td>
              <td v-if="!isPeriod">{{ row2.created_at }}</td>
              <td v-if="filters.filter_by != 'user'">{{ row2.user_name }}</td>
              <td>{{ row2.payment_method_name }}</td>
              <td>{{ row2.amount }}</td>
            </tr>
            <tr :key="'bdsfdf'+index">
              <td :colspan="filters.filter_by == 'payment_method' ? 7 : 6">{{ $t('purchases_payments_periodic_reports.total') }}</td>
              <td>{{ row.total }}</td>
            </tr>
          </template>

          </tbody>
          <tfoot>
          <tr>
            <td :colspan="filters.filter_by == 'payment_method' ? 7 : 6"><b>{{ $t('purchases_payments_periodic_reports.total') }}</b></td>
            <th>{{ dataTotalDetailsList ? dataTotalDetailsList : 0 }}</th>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div class="selector-export" id="selectorExport"></div>

    <div class="export-container">
      <div class="section-for-export" id="sectionForExportHeader">
        <report-main-header :print-type="'PurchasePaymentReport'"></report-main-header>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import LineChart from './components/Line.vue';
import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";
import TableToExcel from "@linways/table-to-excel";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";
import ReportMainHeader from "@/view/content/printing-heads/ReportMainHeader.vue";

export default {
  name: "index-purchase-payment-report",
  components: {ReportMainHeader, LineChart},
  data() {
    return {
      mainRoute: '/reports/purchases',
      mainRouteSummary: '/reports/purchases/purchase-list/summary',
      mainRouteDependency: 'base/dependency',

      currency_name: null,
      supplier: null,

      line_invoice_details: [],

      dataDetailsList: [],
      dataTotalDetailsList: 0,

      dataSummaryList: [],
      dataTotalSummaryList: 0,

      data_report_details: [],
      details_total_sum: 0,

      data_report_summary: [],
      summary_total_sum: 0,


      filters: {
        supplier_id: null,
        from_date: null,
        to_date: null,
        user_id: null,
        filter_by: null,
        payment_method: null,

        period: null,
        range: null,
        f_time_period: 0,
        financial_year: 0,
      },


      data: [],
      suppliers: [],
      user: null,
      users: [],
      employees: [],
      payment_method_list: [],

      isPeriod: true,
      periodLabel: '',
      tab: this.$route.query.tab ? this.$route.query.tab : null,
      json_meta: [
        [{
          key: "charset",
          value: "utf-8",
        },],
      ],
      page: 1,
      page_details: 1,
    }
  },
  watch: {
    user: function (val) {
      if (val) {
        this.filters.user_id = val.id;
      } else {
        this.filters.user_id = null;
      }
    },
    "filters.filter_by": function (val) {
      if (val)
        this.doFilter();
      switch (val) {
        case 'daily':
          this.isPeriod = true;
          this.periodLabel = 'day';
          break;
        case 'weekly':
          this.isPeriod = true;
          this.periodLabel = 'week';
          break;
        case 'monthly':
          this.isPeriod = true;
          this.periodLabel = 'month';
          break;
        case 'yearly':
          this.isPeriod = true;
          this.periodLabel = 'year';
          break;
        case 'supplier':
          this.isPeriod = false;
          this.periodLabel = 'supplier';
          break;
        case 'user':
          this.isPeriod = false;
          this.periodLabel = 'user';
          break;
        case 'payment_method':
          this.isPeriod = false;
          this.periodLabel = 'payment_method';
          break;
        default:
          this.isPeriod = false;
          this.periodLabel = '';
          break;
      }
    },
    supplier: function (val) {
      if (val) {
        this.filters.supplier_id = val.id;
      } else {
        this.filters.supplier_id = null;
      }
    },
  },
  computed: {

    json_fields: function () {
      let fields = {};

      fields[this.$t('purchases_payments_periodic_reports.' + this.periodLabel)] = 'label';

      fields[this.$t('purchases_payments_periodic_reports.total') + ` (${this.currency_name})`] = 'total';

      return fields;
    },

    json_fields_details: function () {
      let fields = {};

      fields[this.$t('purchases_payments_periodic_reports.id')] = 'id';
      fields[this.$t('purchases_payments_periodic_reports.invoice_number')] = 'invoice_code';
      fields[this.$t('purchases_payments_periodic_reports.supplier')] = 'supplier_name';
      fields[this.$t('purchases_payments_periodic_reports.employee')] = 'added_by';
      fields[this.$t('purchases_payments_periodic_reports.payment_method')] = 'payment_method_name';
      fields[this.$t('purchases_payments_periodic_reports.ref')] = 'ref_no';
      fields[this.$t('purchases_payments_periodic_reports.total') + ` (${this.currency_name})`] = 'total';

      return fields;
    },


  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.purchases_reports'), route: '/reports/purchase-reports-links'}, {title: this.$t("MENU.purchases_payments_reports")}]);
    this.filters.filter_by = this.$route.query.filter ? this.$route.query.filter : 'daily';


    // this.getEmployees();
    this.getPaymentMethodList();
    this.getAuthUser();
  },
  methods: {
    ...cssStypeForPrintReport,

    loadMore() {
      this.page = this.page + 1;
      this.getReportInvoiceSummary();
    },
    loadMoreDetails() {
      this.page_details = this.page_details + 1;
      this.getReportInvoiceDetails();
    },
    convertTableToExcelReport(_tableId) {
      TableToExcel.convert(document.getElementById(_tableId), {
        name: this.$t('MENU.purchases_payments_reports')+'.xlsx',
        sheet: {
          name: 'Sheet 1'
        }
      });
    },
    // salesInvoiceExport() {
    //   let _footer = {
    //     label: this.$t('purchases_payments_periodic_reports.total') + ` (${this.currency_name})`,
    //     total: this.summary_total_sum.sum_total_total,
    //   };
    //   let _data = [];
    //   this.data_report_summary.forEach((row) => {
    //     _data.push({
    //       label: row.label,
    //       total: row.total,
    //     });
    //   });
    //   _data.push(_footer);
    //
    //   return _data;
    // },
    // salesInvoiceDetailsExport() {
    //   this.setReportLog('excel', 'purchases payments periodic reports');
    //   let _footer = {
    //     id: this.$t('purchases_payments_periodic_reports.total') + ` (${this.currency_name})`,
    //     invoice_code: '',
    //     supplier_name: '',
    //     added_by: '',
    //     payment_method_name: '',
    //     ref_no: '',
    //     total: this.details_total_sum.sum_total_total,
    //   };
    //   let _data = [];
    //   this.data_report_details.forEach((row) => {
    //     _data.push({
    //       id: row.label,
    //       invoice_code: '',
    //       supplier_name: '',
    //       added_by: '',
    //       payment_method_name: '',
    //       ref_no: '',
    //       total: '',
    //     });
    //     row.details.forEach((sub_row) => {
    //       _data.push({
    //         id: sub_row.id,
    //         invoice_code: sub_row.invoice_code,
    //         supplier_name: sub_row.supplier_name,
    //         added_by: sub_row.added_by,
    //         payment_method_name: sub_row.payment_method_name,
    //         ref_no: sub_row.ref_no,
    //         total: sub_row.total,
    //       });
    //     });
    //     _data.push({
    //       id: this.$t('purchases_payments_periodic_reports.total') + ` (${this.currency_name})`,
    //       invoice_code: '',
    //       supplier_name: '',
    //       added_by: '',
    //       payment_method_name: '',
    //       ref_no: '',
    //       total: row.sum_total,
    //     });
    //   });
    //   _data.push(_footer);
    //
    //   return _data;
    // },

    getRandom() {
      return Math.floor(Math.random() * 10000);
    },

    doFilter() {
      this.page = 1;
      this.page_details = 1;
      this.getReportInvoiceSummary();
      this.getReportInvoiceDetails();

    },
    changeIntervalType(action_type, range = null){
      if (this.filters.period == action_type){
        this.filters.period = null;
        this.filters.f_time_period = 0;
      }else{
        this.filters.period = action_type;
        this.filters.f_time_period = 1;
      }

    },
    resetFilter() {
      this.user = null;
      this.supplier = null;

      this.filters.supplier_id = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.user_id = null;
      this.filters.payment_method = null;
      this.filters.period = null;
    },

    getSuppliers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/suppliers", {params: {filter: filter}}).then((response) => {
          this.suppliers = response.data.data;
        });
    },
    getUsers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/users", {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
    },
    getAuthUser() {
      ApiService.get(`/get_auth`).then((response) => {
        this.currency_name = response.data.data.currency_name;
      });
    },
    // getEmployees() {
    //   ApiService.get(this.mainRouteDependency + "/employees").then((response) => {
    //     this.employees = response.data.data;
    //   });
    // },
    getPaymentMethodList() {
      ApiService.get(this.mainRouteDependency + "/payments_methods").then((response) => {
        this.payment_method_list = response.data.data;
      });
    },
    /*****
     * details
     */
    // getLineChartInvoiceDetails() {
    //   ApiService.get(`${this.mainRoute}/purchase-chart`, {params: {...this.filters, page: (this.page ? this.page : 1), type: 'bar'}}).then((response) => {
    //     this.line_invoice_details = response.data;
    //   });
    // },

    getReportInvoiceDetails() {
      let _limit = (this.page ? this.page : 1) * 150;
      ApiService.get(`${this.mainRoute}/purchase-list`, {params: {...this.filters, limit: _limit}}).then((response) => {
        this.data_report_details = response.data.data.data.items;
        this.details_total_sum = response.data.data.data.total;
      });
    },
    getReportInvoiceSummary() {
      let _limit = (this.page ? this.page : 1) * 150;
      ApiService.get(`${this.mainRouteSummary}`, {params: {...this.filters, limit: _limit}}).then((response) => {
        this.data_report_summary = response.data.data.items;
        this.summary_total_sum = response.data.data.total;
      });
    },
    getAllDataForExport(tableId, _export) {
      ApiService.get(`${this.mainRoute}/purchase-list`, {params: {...this.filters, limit: 99999999999999}}).then((response) => {
        this.dataDetailsList = response.data.data.data.items;
        this.dataTotalDetailsList = response.data.data.data.total;
        setTimeout(() => {
          this.convertTableToExcelReport(tableId);
        }, 100)
      });
    },
    getAllDataForExportSummary(tableId, _export) {
      ApiService.get(`${this.mainRouteSummary}`, {params: {...this.filters, limit: 99999999999999}}).then((response) => {
        this.dataSummaryList = response.data.data.items;
        this.dataTotalSummaryList = response.data.data.total;
        setTimeout(() => {
          this.convertTableToExcelReport(tableId);
        }, 100)
      });
    },
    /**
     * get for summary tab
     */
    // getReportInvoiceSummary() {
    //   ApiService.get(`${this.mainRoute}/purchase-list`, {params: {...this.filters, page: (this.page ? this.page : 1), data_type: 'list'}}).then((response) => {
    //     this.data_report_summary = response.data.data;
    //     this.summary_total_sum = response.data.total;
    //   });
    // },
    printData(tableId) {
      this.setReportLog('pdf', 'purchases payments periodic reports');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        // newWin.close();
      }, 100)
    },
    printPDFData(tableId, type) {
      this.setReportLog('pdf', 'purchases payments periodic reports');

      // let divToPrint = document.getElementById(tableId);
      // let divTitleToPrint = document.getElementById(tableId + 'Title');
      // let newWin = window.open("");
      // newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
      // setTimeout(() => {
      //   newWin.print();
      //   newWin.close();
      // }, 100)

      exportPDFInnerAction(tableId , type, this.$t('purchases_payments_periodic_reports.purchases_payments_periodic_reports'), this.$t('purchases_payments_periodic_reports.purchases_payments_periodic_reports'));

    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }
  },
};
</script>
<style>
.html2pdf__overlay{
  opacity: 0 !important;
  z-index: -1000;

}
.selector-export{
  opacity: 0 !important;
  z-index: -1000 !important;
  width: 0.0001px !important;
  height: 0.0001px !important;
  overflow: auto;
}

</style>
